<template>
  <div>
    <template v-if="this.$store.state.showNav">
      <CFooter :fixed="true">
        <nav class="navbar fixed-bottom navbar-expand-lg navbar-light bg-light" style="height: 80px;">
            <div>
              <center>
                <a v-on:click="changeToHome()">
                  <img src="../assets/image/UI Member PHOTO/Icon-01.png" class="img-fluid" alt="Responsive image" width="30px;"/>
                  <p style="font-size: 10px; margin: 0 0 0 0; color: #808080;">หน้าหลัก</p>
                </a>
              </center>
            </div>
            <div>
              <center>
                <a v-on:click="changeToReward()">
                  <img src="../assets/image/UI Member PHOTO/Icon-04.png" class="img-fluid" alt="Responsive image" width="30px;"/>
                  <p style="font-size: 10px; margin: 0 0 0 0; color: #808080;">แลกของรางวัล</p>
                </a>
              </center>
            </div>
            <div>
              <center>
                <a v-on:click="changeToOperation()">
                  <img src="../assets/image/UI Member PHOTO/Icon-03.png" class="img-fluid" alt="Responsive image" width="30px;"/>
                  <p style="font-size: 10px; margin: 0 0 0 0; color: #808080;">การทำงาน</p>
                </a>
              </center>
            </div>
            <div>
              <center>
                <a v-on:click="changeToNews()">
                  <img src="../assets/image/UI Member PHOTO/Icon-05.png" class="img-fluid" alt="Responsive image" width="30px;"/>
                  <p style="font-size: 10px; margin: 0 0 0 0; color: #808080;">ข่าวอัพเดท</p>
                </a>
              </center>
            </div>
            <div>
              <center>
                <a v-on:click="changeToSetting()">
                  <img src="../assets/image/UI Member PHOTO/Icon-02.png" class="img-fluid" alt="Responsive image" width="30px;"/>
                  <p style="font-size: 10px; margin: 0 0 0 0; color: #808080;">การตั้งค่า</p>
                </a>
              </center>
            </div>
        </nav>
      </CFooter>
    </template>


    <v-dialog
        v-model="$store.state.popupregis"
        persistent
        width="100%"
      >
        <v-card>
          <div style="background-color:#7c52f4; height: 40px;">
            <h1 style="font-size: 18px; margin: 0 0 0 20px; padding-top: 8px; color:#FFF;">คุณลงทะเบียนสำเร็จ.</h1>
          </div>
          <div style="padding: 10px;">
            <CCard >
              <CCardBody style="background-color:#e5e5e5;">
                <div class="d-flex" style="margin: 10px;">
                <div style="width: 30%;">
                  <center>
                    <img src="../assets/image/UI Member PHOTO/Member icon-02.png" class="img-fluid" alt="Responsive image" style="width: 70%;" />
                  </center>
                </div>
                <div style="width: 70%; display: block;">
                  <h2  style="font-size:14px; text-align: center; margin: auto auto 5px auto;">โปรโมชั่นสมาชิกใหม่</h2> 
                  <h2  style="font-size:14px; text-align: center; margin: auto auto;">"ซักฟรี 1 ครั้ง และ อบฟรี 1 ครั้ง"</h2> 
                </div>
              </div>
              </CCardBody>
            </CCard>
            <center>
              <CButton v-on:click="closeCModalRegis()" style="background-color:#1ec688; color:#FFFFFF; margin: 0 0px "><p style="margin: 0;">ตกลง</p></CButton>
            </center>
          </div>
        </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  name: 'TheFooter',
  data() {
    return {
      
   };
  },
  mounted() {
    
  },
  methods: {
    changeToHome(){
      this.$router.push('/memberww')
    },
    changeToReward(){
      this.$router.push('/rewardww')
    },
    changeToOperation(){
      this.$router.push('/operationww?pageww=operation')
    },
    changeToNews(){
      this.$router.push('/newsww')
      // this.$router.push('/loginww')
    },
    changeToSetting(){
      this.$router.push('/settingww')
    },
    closeCModalRegis(){
      this.$store.state.popupregis = false
      this.$store.state.popupregis_buff = false
    },
    checkCModal(e){
      this.$store.state.popupregis = e;
      this.$store.state.popupregis_buff = false
    },
  }
}
</script>
